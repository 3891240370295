body {
	&::-webkit-scrollbar {
		background: black;
		width: 5px;

		&-thumb {
			background-color: $blue;
			border-radius: 20px;
		}
	}
}

.flexColumn {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.flexRowEven {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 20px;
	align-items: center;

	.space-evenly {
		display: flex;
		justify-content: space-evenly;
		width: 100%;
	}
}

.tableChevron {
	cursor: pointer;
}

.MuiCollapse-wrapperInner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 10px;
}

.inner-wrapper {
	padding: 0;
}

.inner-table {
	max-width: 98%;
}

.home-link {
	color: #fff;
	&:visited {
		color: #fff;
	}
}

.poll-chart {
	margin-top: 20px;

	.margin-spacer {
		margin: 10px auto 20px;
	}
}

.cancel {
	font-size: 12px;
	text-align: center;
	margin-top: 30px;
}

button {
	transition: all linear 0.3s;
}

.user-form {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 100vw;
	height: 100vh;

	.register-form,
	.login-form,
	.forgot-password-form {
		display: flex;
		width: 600px;

		img {
			width: 64px;
		}

		h3 {
			margin: 30px auto;
			font-weight: bold;
		}

		.register-bold {
			font-weight: bold;
		}

		.checkits {
			display: flex;
			width: 65%;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			margin: 20px auto;

			.check-item {
				display: flex;
				justify-content: space-around;
				align-items: center;
				align-content: center;

				svg {
					height: 20px;
					color: $blue;
				}

				h6 {
					font-size: 13px;
				}
			}
		}

		.register-subhead {
			font-size: 19px;
			margin: 20px auto;
		}

		.card-actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 10px;

			a {
				&:visited {
					color: #000;
				}
			}
		}

		.signin-link {
			&:visited {
				color: #000;
			}
		}
	}

	.forgot-password-form {
		.form-paper {
			padding: 10px;
			height: auto;
			width: 400px;
			margin: 20px auto;
		}

		h3 {
			margin-bottom: 0;
		}

		.subheading {
			margin-bottom: 30px;
			font-size: 18px;
		}

		button {
			margin: 10px auto;
		}
	}
}

.navbar {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	align-content: center;
	text-align: center;
	align-items: center;
	position: fixed;
	z-index: 100;
	transition: all linear 0.3s;

	&.hidden {
		display: none;
	}

	&.scrolled {
		background-color: rgba(14, 11, 51, 0.932);
	}

	.appbar {
		display: flex;
		justify-content: center;
		background-color: transparent;
		padding: 8px 20px;
		height: 58px;

		.toolbar {
			display: flex;
			justify-content: space-between;

			.links {
				display: flex;
				justify-content: space-between;
				width: 200px;
				margin: 0 10px;

				a {
					margin: 0 10px;
					color: #fff;

					#dashboard-link,
					#tools-link {
						font-weight: bold;

						&.active {
							transition: all linear 0.3s;
							border-bottom: 2px solid $blue;
						}
					}
				}
			}

			.nav-left {
				width: 200px;

				.logo-container {
					display: flex;
					justify-content: left;
					align-items: center;
					width: 160px;
					margin: 0;

					.home-link {
						img {
							height: 40px;
						}
					}
				}
			}

			.nav-right {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 180px;
			}
		}
	}
}

.background-container {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	background: url('../images/hero-section-bg.svg') no-repeat center center/cover;
}

.dashboard-container {
	.dashboard {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-content: center;
		align-items: center;
		box-sizing: border-box;

		.dashboard-loader {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100vh;
		}

		.first-poll-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 100vh;

			img {
				width: auto;
				height: 300px;
			}

			h2 {
				margin-top: 50px;
				color: #fff;
			}

			.add-chart-button {
				margin-top: 20px;
			}
		}
	}
}

.new-poll-form {
	.disabled-vote-altering {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
		border: 1px solid lightgrey;
		border-radius: 5px;
		padding: 10px 11px;
		color: grey;

		.upgrade-plan {
			display: flex;

			a {
				margin-left: 10px;
			}
		}

		p {
			text-align: center;
			font-size: 17px;
		}
	}
}

.edit-poll-form-container {
	.notice {
		display: flex;
		width: 100%;
		justify-content: center;
	}
	.edit-poll-form {
		.disabled-vote-altering {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 5px;
			border: 1px solid lightgrey;
			border-radius: 5px;
			padding: 10px 11px;
			color: grey;
		}

		#auto-forward-link {
			.disabled-auto-forward {
				margin-top: 5px;
				border: 1px solid lightgrey;
				border-radius: 5px;
				padding: 10px 11px;
				color: grey;
			}
		}
	}
}

.poll-tables {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	text-align: center;
	max-width: '1000px';
	margin: '0 auto';
	padding: '0 10px';
	height: '100%';

	.poll-table-container {
		width: 100%;
		margin-top: 100px;

		.table-container {
			margin: 10px auto;
			max-width: 1000px;
			position: relative;

			.table-drag-indicator {
				position: absolute;
				left: 5px;
				top: 23px;
				width: 20px;
				color: rgba(211, 211, 211, 0.4);

				&:hover {
					color: rgba(211, 211, 211, 0.6);
					cursor: move;
				}
			}
		}
	}
}

.delete-modal {
	.delete-modal-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px;
		width: 325px;

		.delete-modal-form {
			display: flex;
			align-content: center;

			h6 {
				margin-bottom: 10px;
			}

			.checkbox-group {
				font-size: 8px;
				margin-right: 2px;
				margin-top: 15px;
			}

			.delete-poll-button-group {
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
			}
		}
	}
}

.poll-name {
	a {
		.poll-name-text {
			display: flex;
			justify-content: center;
			align-content: center;
			text-align: center;

			span {
				margin-left: 5px;
				color: grey;
				margin-top: 3px;
			}
		}
	}
}

.link-switch {
	svg {
		color: $blue;

		&:hover {
			cursor: pointer;
		}
	}
}

.snippets {
	.social-proof-card-container {
		.social-proof-card {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			position: relative;

			.edit-social-proof {
				color: $blue;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

.my-account-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;

	.my-account {
		margin-top: 100px;
		width: 100%;
		padding: 20px 50px;
		box-sizing: border-box;

		.plan-loading {
			display: flex;
			justify-content: center;
		}

		.plans-header {
			margin-bottom: 20px;

			h2 {
				font-size: 1.5rem;
				color: #fff;
			}
		}

		.billing-tabs-container {
			.tabs {
				.tab {
					font-size: 1rem;
					color: #fff;
				}
			}
		}

		.plan-options-panel {
			display: flex;
			width: 100%;
			justify-content: center;

			.plan-options-container {
				display: flex;

				.manage-plan-section {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					width: 100%;

					.monthly-yearly-slider {
						display: flex;
						align-items: center;
						width: 100%;
						justify-content: center;
						color: #fff;
						margin-bottom: 20px;
					}

					.plans {
						display: flex;
						justify-content: center;
						width: 100%;
						padding: 10px;
						flex-wrap: wrap;

						.pricing-table {
							text-align: center;

							.top-spacer {
								display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								height: 50px;
								background-color: $blue;
								color: #fff;
								border-radius: 10px 10px 0 0;

								&.transparent {
									background: transparent;
								}
							}

							.pricing-card {
								width: 200px;
								height: 430px;
								margin-bottom: 10px;

								.card-header {
									background: $ltGreyBack;

									&.free {
										&.active {
											background: $blue;
											color: #fff;
											outline: 3px solid $activePlanAccent;
										}
									}

									&.basic {
										&.active {
											background: $blue;
											color: #fff;
											outline: 3px solid $activePlanAccent;
										}
									}

									&.pro {
										&.active {
											background: $blue;
											color: #fff;
											outline: 3px solid $activePlanAccent;
										}
									}

									&.ultimate {
										&.active {
											background: $blue;
											color: #fff;
											outline: 3px solid $activePlanAccent;
										}
									}

									&.agency {
										&.active {
											background: $blue;
											color: #fff;
											outline: 3px solid $activePlanAccent;
										}
									}
								}

								.plan-action-button {
									margin: 20px auto;
								}

								.pricing-content {
									padding: 20px 0;

									.plan-action-button {
										padding: 0 20px;
									}

									.includes {
										font-size: 14px;
										display: flex;
										align-items: center;
										justify-content: center;
										height: 40px;
										background: $ltGreyBack;
										margin: 10px auto;
									}

									.beta-line-item {
										color: red;
										margin-top: 10px;
									}
								}
							}
						}
					}
				}
			}
		}

		.invoices-options-panel {
			.payment-details {
				display: flex;
				flex-direction: column;
				min-width: 350px;
				padding: 10px;
				margin: 10px 0;
				align-items: center;

				.no-payment-method {
					color: #fff;
				}

				.payment-method {
					display: flex;
					flex-direction: column;

					.card-details {
						display: flex;
						align-items: center;
						margin-top: 10px;

						img {
							height: 30px;
							margin-right: 10px;
						}
					}

					.next-due {
						margin-top: 10px;
					}

					.cancel-subscription {
						color: lightgray;
					}
				}
			}

			.invoices {
				.invoice-not-paid {
					padding: 3px 10px;
					border-radius: 20px;
					background: $notPaidPill;
					color: #fff;
				}

				.invoice-paid {
					padding: 3px 10px;
					border-radius: 20px;
					background: $paidPill;
					color: #fff;
					max-width: 50px;
					margin: 0 auto;
				}
			}
		}
	}
}

.update-subscription-modal {
	.upgrade-subscription-actions {
		display: flex;
		flex-direction: column;

		.action-buttons {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
	}
}

.checkout-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;

	#payment-form {
		width: 600px;
		padding: 20px;

		#submit {
			width: 200px;
			color: #fff;
		}
	}
}

.success-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	text-align: center;
	height: 100vh;
	width: 100%;
	color: #fff;
}

.profile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;

	.add-user {
		display: flex;
		flex-direction: column;
		.profile-form {
			.profile-buttons {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.danger-zone {
		width: 60%;
		max-width: 630px;
		margin-top: 100px;

		svg {
			color: #fff;
		}

		.danger-accordion {
			border-radius: 3px;

			#panel1a-header {
				background-color: rgb(218, 3, 3);
				color: #fff;
			}

			.MuiAccordion-region {
				.danger-accordion-details {
					display: flex;
					justify-content: space-around;
					align-items: center;
					align-content: center;
					width: 100%;
				}
			}
		}
	}
}

.tools-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;

	.tools-header {
		margin-top: 100px;
		width: 100%;
		padding: 20px 50px;
		box-sizing: border-box;
	}

	.tools {
		display: flex;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;

		.title {
			color: $blue;
			font-weight: bold;
			text-align: center;
		}
		.subtitle {
			font-family: Arial, Helvetica, sans-serif;
			text-align: center;
			line-height: 1.1;
		}
		.tool-card {
			display: flex;
			flex-direction: column;
			margin: 10px;
			width: 300px;

			.tool-card-image {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 250px;
			}

			.card-actions {
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;

				a {
					display: flex;
					align-items: center;
					align-content: center;
					align-items: center;
					justify-content: center;
					width: 100%;
				}
			}
		}
	}
}
