html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body::-webkit-scrollbar {
  background: black;
  width: 5px;
}
body::-webkit-scrollbar-thumb {
  background-color: #1976d2;
  border-radius: 20px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flexRowEven {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  align-items: center;
}
.flexRowEven .space-evenly {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.tableChevron {
  cursor: pointer;
}

.MuiCollapse-wrapperInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.inner-wrapper {
  padding: 0;
}

.inner-table {
  max-width: 98%;
}

.home-link {
  color: #fff;
}
.home-link:visited {
  color: #fff;
}

.poll-chart {
  margin-top: 20px;
}
.poll-chart .margin-spacer {
  margin: 10px auto 20px;
}

.cancel {
  font-size: 12px;
  text-align: center;
  margin-top: 30px;
}

button {
  transition: all linear 0.3s;
}

.user-form {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: 100vh;
}
.user-form .register-form,
.user-form .login-form,
.user-form .forgot-password-form {
  display: flex;
  width: 600px;
}
.user-form .register-form img,
.user-form .login-form img,
.user-form .forgot-password-form img {
  width: 64px;
}
.user-form .register-form h3,
.user-form .login-form h3,
.user-form .forgot-password-form h3 {
  margin: 30px auto;
  font-weight: bold;
}
.user-form .register-form .register-bold,
.user-form .login-form .register-bold,
.user-form .forgot-password-form .register-bold {
  font-weight: bold;
}
.user-form .register-form .checkits,
.user-form .login-form .checkits,
.user-form .forgot-password-form .checkits {
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 20px auto;
}
.user-form .register-form .checkits .check-item,
.user-form .login-form .checkits .check-item,
.user-form .forgot-password-form .checkits .check-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.user-form .register-form .checkits .check-item svg,
.user-form .login-form .checkits .check-item svg,
.user-form .forgot-password-form .checkits .check-item svg {
  height: 20px;
  color: #1976d2;
}
.user-form .register-form .checkits .check-item h6,
.user-form .login-form .checkits .check-item h6,
.user-form .forgot-password-form .checkits .check-item h6 {
  font-size: 13px;
}
.user-form .register-form .register-subhead,
.user-form .login-form .register-subhead,
.user-form .forgot-password-form .register-subhead {
  font-size: 19px;
  margin: 20px auto;
}
.user-form .register-form .card-actions,
.user-form .login-form .card-actions,
.user-form .forgot-password-form .card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.user-form .register-form .card-actions a:visited,
.user-form .login-form .card-actions a:visited,
.user-form .forgot-password-form .card-actions a:visited {
  color: #000;
}
.user-form .register-form .signin-link:visited,
.user-form .login-form .signin-link:visited,
.user-form .forgot-password-form .signin-link:visited {
  color: #000;
}
.user-form .forgot-password-form .form-paper {
  padding: 10px;
  height: auto;
  width: 400px;
  margin: 20px auto;
}
.user-form .forgot-password-form h3 {
  margin-bottom: 0;
}
.user-form .forgot-password-form .subheading {
  margin-bottom: 30px;
  font-size: 18px;
}
.user-form .forgot-password-form button {
  margin: 10px auto;
}

.navbar {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-content: center;
  text-align: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  transition: all linear 0.3s;
}
.navbar.hidden {
  display: none;
}
.navbar.scrolled {
  background-color: rgba(14, 11, 51, 0.932);
}
.navbar .appbar {
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 8px 20px;
  height: 58px;
}
.navbar .appbar .toolbar {
  display: flex;
  justify-content: space-between;
}
.navbar .appbar .toolbar .links {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin: 0 10px;
}
.navbar .appbar .toolbar .links a {
  margin: 0 10px;
  color: #fff;
}
.navbar .appbar .toolbar .links a #dashboard-link,
.navbar .appbar .toolbar .links a #tools-link {
  font-weight: bold;
}
.navbar .appbar .toolbar .links a #dashboard-link.active,
.navbar .appbar .toolbar .links a #tools-link.active {
  transition: all linear 0.3s;
  border-bottom: 2px solid #1976d2;
}
.navbar .appbar .toolbar .nav-left {
  width: 200px;
}
.navbar .appbar .toolbar .nav-left .logo-container {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 160px;
  margin: 0;
}
.navbar .appbar .toolbar .nav-left .logo-container .home-link img {
  height: 40px;
}
.navbar .appbar .toolbar .nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: url("../images/hero-section-bg.svg") no-repeat center center/cover;
}

.dashboard-container .dashboard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
}
.dashboard-container .dashboard .dashboard-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.dashboard-container .dashboard .first-poll-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
.dashboard-container .dashboard .first-poll-container img {
  width: auto;
  height: 300px;
}
.dashboard-container .dashboard .first-poll-container h2 {
  margin-top: 50px;
  color: #fff;
}
.dashboard-container .dashboard .first-poll-container .add-chart-button {
  margin-top: 20px;
}

.new-poll-form .disabled-vote-altering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px 11px;
  color: grey;
}
.new-poll-form .disabled-vote-altering .upgrade-plan {
  display: flex;
}
.new-poll-form .disabled-vote-altering .upgrade-plan a {
  margin-left: 10px;
}
.new-poll-form .disabled-vote-altering p {
  text-align: center;
  font-size: 17px;
}

.edit-poll-form-container .notice {
  display: flex;
  width: 100%;
  justify-content: center;
}
.edit-poll-form-container .edit-poll-form .disabled-vote-altering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px 11px;
  color: grey;
}
.edit-poll-form-container .edit-poll-form #auto-forward-link .disabled-auto-forward {
  margin-top: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px 11px;
  color: grey;
}

.poll-tables {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  max-width: "1000px";
  margin: "0 auto";
  padding: "0 10px";
  height: "100%";
}
.poll-tables .poll-table-container {
  width: 100%;
  margin-top: 100px;
}
.poll-tables .poll-table-container .table-container {
  margin: 10px auto;
  max-width: 1000px;
  position: relative;
}
.poll-tables .poll-table-container .table-container .table-drag-indicator {
  position: absolute;
  left: 5px;
  top: 23px;
  width: 20px;
  color: rgba(211, 211, 211, 0.4);
}
.poll-tables .poll-table-container .table-container .table-drag-indicator:hover {
  color: rgba(211, 211, 211, 0.6);
  cursor: move;
}

.delete-modal .delete-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 325px;
}
.delete-modal .delete-modal-content .delete-modal-form {
  display: flex;
  align-content: center;
}
.delete-modal .delete-modal-content .delete-modal-form h6 {
  margin-bottom: 10px;
}
.delete-modal .delete-modal-content .delete-modal-form .checkbox-group {
  font-size: 8px;
  margin-right: 2px;
  margin-top: 15px;
}
.delete-modal .delete-modal-content .delete-modal-form .delete-poll-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.poll-name a .poll-name-text {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.poll-name a .poll-name-text span {
  margin-left: 5px;
  color: grey;
  margin-top: 3px;
}

.link-switch svg {
  color: #1976d2;
}
.link-switch svg:hover {
  cursor: pointer;
}

.snippets .social-proof-card-container .social-proof-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}
.snippets .social-proof-card-container .social-proof-card .edit-social-proof {
  color: #1976d2;
}
.snippets .social-proof-card-container .social-proof-card .edit-social-proof:hover {
  cursor: pointer;
}

.my-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.my-account-container .my-account {
  margin-top: 100px;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
}
.my-account-container .my-account .plan-loading {
  display: flex;
  justify-content: center;
}
.my-account-container .my-account .plans-header {
  margin-bottom: 20px;
}
.my-account-container .my-account .plans-header h2 {
  font-size: 1.5rem;
  color: #fff;
}
.my-account-container .my-account .billing-tabs-container .tabs .tab {
  font-size: 1rem;
  color: #fff;
}
.my-account-container .my-account .plan-options-panel {
  display: flex;
  width: 100%;
  justify-content: center;
}
.my-account-container .my-account .plan-options-panel .plan-options-container {
  display: flex;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .monthly-yearly-slider {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #fff;
  margin-bottom: 20px;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table {
  text-align: center;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .top-spacer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  background-color: #1976d2;
  color: #fff;
  border-radius: 10px 10px 0 0;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .top-spacer.transparent {
  background: transparent;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card {
  width: 200px;
  height: 430px;
  margin-bottom: 10px;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .card-header {
  background: #edf0f7;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .card-header.free.active {
  background: #1976d2;
  color: #fff;
  outline: 3px solid greenyellow;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .card-header.basic.active {
  background: #1976d2;
  color: #fff;
  outline: 3px solid greenyellow;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .card-header.pro.active {
  background: #1976d2;
  color: #fff;
  outline: 3px solid greenyellow;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .card-header.ultimate.active {
  background: #1976d2;
  color: #fff;
  outline: 3px solid greenyellow;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .card-header.agency.active {
  background: #1976d2;
  color: #fff;
  outline: 3px solid greenyellow;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .plan-action-button {
  margin: 20px auto;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .pricing-content {
  padding: 20px 0;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .pricing-content .plan-action-button {
  padding: 0 20px;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .pricing-content .includes {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #edf0f7;
  margin: 10px auto;
}
.my-account-container .my-account .plan-options-panel .plan-options-container .manage-plan-section .plans .pricing-table .pricing-card .pricing-content .beta-line-item {
  color: red;
  margin-top: 10px;
}
.my-account-container .my-account .invoices-options-panel .payment-details {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
}
.my-account-container .my-account .invoices-options-panel .payment-details .no-payment-method {
  color: #fff;
}
.my-account-container .my-account .invoices-options-panel .payment-details .payment-method {
  display: flex;
  flex-direction: column;
}
.my-account-container .my-account .invoices-options-panel .payment-details .payment-method .card-details {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.my-account-container .my-account .invoices-options-panel .payment-details .payment-method .card-details img {
  height: 30px;
  margin-right: 10px;
}
.my-account-container .my-account .invoices-options-panel .payment-details .payment-method .next-due {
  margin-top: 10px;
}
.my-account-container .my-account .invoices-options-panel .payment-details .payment-method .cancel-subscription {
  color: lightgray;
}
.my-account-container .my-account .invoices-options-panel .invoices .invoice-not-paid {
  padding: 3px 10px;
  border-radius: 20px;
  background: #f78282;
  color: #fff;
}
.my-account-container .my-account .invoices-options-panel .invoices .invoice-paid {
  padding: 3px 10px;
  border-radius: 20px;
  background: #56df56;
  color: #fff;
  max-width: 50px;
  margin: 0 auto;
}

.update-subscription-modal .upgrade-subscription-actions {
  display: flex;
  flex-direction: column;
}
.update-subscription-modal .upgrade-subscription-actions .action-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.checkout-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.checkout-page #payment-form {
  width: 600px;
  padding: 20px;
}
.checkout-page #payment-form #submit {
  width: 200px;
  color: #fff;
}

.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  color: #fff;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.profile .add-user {
  display: flex;
  flex-direction: column;
}
.profile .add-user .profile-form .profile-buttons {
  display: flex;
  justify-content: space-between;
}
.profile .danger-zone {
  width: 60%;
  max-width: 630px;
  margin-top: 100px;
}
.profile .danger-zone svg {
  color: #fff;
}
.profile .danger-zone .danger-accordion {
  border-radius: 3px;
}
.profile .danger-zone .danger-accordion #panel1a-header {
  background-color: #da0303;
  color: #fff;
}
.profile .danger-zone .danger-accordion .MuiAccordion-region .danger-accordion-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
}

.tools-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.tools-container .tools-header {
  margin-top: 100px;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
}
.tools-container .tools {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.tools-container .tools .title {
  color: #1976d2;
  font-weight: bold;
  text-align: center;
}
.tools-container .tools .subtitle {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 1.1;
}
.tools-container .tools .tool-card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 300px;
}
.tools-container .tools .tool-card .tool-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.tools-container .tools .tool-card .card-actions {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.tools-container .tools .tool-card .card-actions a {
  display: flex;
  align-items: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}

